.container {
  height: 100vh;
  & div.subheader {
    font-size: 20px;
    font-weight: 700;
    padding: 0px 0px 20px 0px;
    :nth-child(1) {
      font-size: 20px;
      font-family: nunito;
      text-transform: uppercase;
    }
    :nth-child(2) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
