@mixin content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  flex-direction: row;
}

.list {
  color: yellow;
  font-size: 20px;
  font-weight: bold;
}

.container {
  display: flex;
  align-items: center;
  position: sticky;
  box-shadow: green 14px -2px 10px 1px;
  justify-content: space-between;
  height: 72px;
  & .left,
  & .right {
    @include content();
  }
  & .right {
    padding-right: 15px;
    & .avatar {
      margin-left: 20px;
      font-size: 20px;
      height: 40px;
      width: 40px;
      cursor: pointer;
    }
  }
  & .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      height: 171px;
      width: 200px;
    }
  }
}
