.container {
  & .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    & div.addBtn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      float: right;
    }
  }
}
